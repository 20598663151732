import React, { Suspense, lazy } from "react";
// 3rd-party
import { Switch, Route, Redirect } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import {
  getUserStorage,
  getSenderStorage,
  getReceivertorage,
  getPaymentStorage,
  getOrderDetailStorage,
} from "../services/localStorage";
// Component-for-scroll-to-top
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
// pages
const HomePage = lazy(() => import("../pages/HomePage"));
const Register = lazy(() => import("../pages/Register"));
const SetupAccount = lazy(() => import("../pages/SetupAccountPage"));
const Login = lazy(() => import("../pages/Login"));
const TransferStepOne = lazy(() =>
  import("../pages/v1/TransactionProcess/TransferStepOne")
);
const TransferStepTwo = lazy(() =>
  import("../pages/v1/TransactionProcess/TransferStepTwo")
);
const Confirmation = lazy(() =>
  import("../pages/v1/TransactionProcess/Confirmation")
);
const PaymentDetail = lazy(() =>
  import("../pages/v1/TransactionProcess/PaymentDetail")
);
const Finish = lazy(() => import("../pages/v1/TransactionProcess/Finish"));
const BankDetails = lazy(() =>
  import("../pages/v2/TransactionProcess/bankDetails")
);
const OrderDetailPrint = lazy(() =>
  import("../pages/v1/TransactionProcess/OrderDetailPrint")
);
const OrderHistory = lazy(() =>
  import("../pages/v1/TransactionProcess/OrderHistory")
);
// v2-transaction-pages-start
const SenderInfo = lazy(() =>
  import("../pages/v2/TransactionProcess/senderInfo")
);
const ReceiverInfo = lazy(() =>
  import("../pages/v2/TransactionProcess/receiverInfo")
);
const PaymentInfo = lazy(() =>
  import("../pages/v2/TransactionProcess/payment")
);
const ConfirmationInfo = lazy(() =>
  import("../pages/v2/TransactionProcess/confirmation")
);
const FinishPage = lazy(() => import("../pages/v2/TransactionProcess/finish"));
// v2-transaction-pages-end
const NotFoundPage = lazy(() => import("../pages/NotFoundPage"));
const RegisterSuccessful = lazy(() => import("../pages/RegisterSuccessful"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const ResetSuccessful = lazy(() => import("../pages/ResetSuccessful"));
const ForgetPasswordWithEmail = lazy(() =>
  import("../pages/ForgetPasswordWithEmail")
);
const TermsAndConditions = lazy(() => import("../pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const Profile = lazy(() => import("../pages/Profile"));
const ErrorPage = lazy(() => import("../pages/ErrorPage"));
const AccountVerification = lazy(() => import("../pages/AccountVerification"));
const AccountDeletion = lazy(() => import("../pages/AccountDeletion"));
const Maintenance = lazy(() => import("../pages/Maintenance"));

import config from "../config/config.json";

const url = config.routeUrl;

const spinnerStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function routes() {
  return (
    <>
      <Suspense
        fallback={<SyncLoader color={"#030870"} size={17} css={spinnerStyle} />}
      >
        <ScrollToTop />
        <Switch>
          {/* <Route exact path="/" component={Maintenance} /> */}
          <Route exact path={url} component={HomePage} />
          <Route
            exact
            path={url + "login"}
            component={(props) =>
              !getUserStorage() ? <Login {...props} /> : <Redirect to={url} />
            }
          />
          <Route
            exact
            path={url + "register"}
            component={(props) =>
              !getUserStorage() ? (
                <Register {...props} />
              ) : (
                <Redirect to={url} />
              )
            }
          />
          <Route exact path={url + "setupAccount"} component={SetupAccount} />
          <Route
            exact
            path={url + "transaction/sender-information"}
            component={(props) =>
              getUserStorage() ? (
                <TransferStepOne {...props} />
              ) : (
                <Redirect to={url + "login"} />
              )
            }
          />
          <Route
            exact
            path={url + "transaction/receiver-information"}
            component={(props) =>
              getSenderStorage() ? (
                <TransferStepTwo {...props} />
              ) : (
                <Redirect to={url + "transaction/sender-information"} />
              )
            }
          />
          <Route
            exact
            path={url + "transaction/payment-detail"}
            component={(props) =>
              getSenderStorage() && getReceivertorage() ? (
                <PaymentDetail {...props} />
              ) : (
                <Redirect to={url + "transaction/sender-information"} />
              )
            }
          />
          <Route
            exact
            path={url + "transaction/confirmation"}
            component={(props) =>
              getSenderStorage() &&
              getReceivertorage() &&
              getPaymentStorage() ? (
                <Confirmation {...props} />
              ) : (
                <Redirect to={url + "transaction/sender-information"} />
              )
            }
          />
          <Route
            exact
            path={url + "transaction/finish"}
            component={(props) =>
              getSenderStorage() &&
              getReceivertorage() &&
              getPaymentStorage() ? (
                <Finish {...props} />
              ) : (
                <Redirect to={url + "transaction/sender-information"} />
              )
            }
          />
          <Route
            exact
            path={url + "order-history"}
            component={(props) =>
              getUserStorage() ? (
                <OrderHistory {...props} />
              ) : (
                <Redirect to={url + "login"} />
              )
            }
          />
          <Route
            exact
            path={url + "transaction/order-detail-print"}
            component={(props) =>
              getOrderDetailStorage() ? (
                <OrderDetailPrint {...props} />
              ) : (
                <Redirect to={url + "order-history"} />
              )
            }
          />
          {/* // v2-transaction-pages-start */}
          <Route
            exact
            path={url + "transaction/v2/sender-info"}
            component={(props) =>
              getUserStorage() ? (
                <SenderInfo {...props} />
              ) : (
                <Redirect to={url + "login"} />
              )
            }
          />
          <Route
            exact
            path={url + "transaction/v2/receiver-info"}
            component={(props) =>
              getUserStorage() && getSenderStorage() ? (
                <ReceiverInfo {...props} />
              ) : (
                <Redirect to={url + "transaction/v2/sender-info"} />
              )
            }
          />
          <Route
            exact
            path={url + "transaction/v2/payment"}
            component={(props) =>
              getUserStorage() ? (
                <PaymentInfo {...props} />
              ) : (
                <Redirect to={url + "login"} />
              )
            }
          />
          <Route
            exact
            path={url + "transaction/v2/confirmation"}
            component={(props) =>
              getUserStorage() ? (
                <ConfirmationInfo {...props} />
              ) : (
                <Redirect to={url + "login"} />
              )
            }
          />
          <Route
            exact
            path={url + "transaction/v2/finish"}
            component={(props) =>
              getUserStorage() ? (
                <FinishPage {...props} />
              ) : (
                <Redirect to={url + "login"} />
              )
            }
          />
          <Route
            exact
            path={url + "transaction/v2/bank-details"}
            component={(props) =>
              getUserStorage() ? (
                <BankDetails {...props} />
              ) : (
                <Redirect to={url + "login"} />
              )
            }
          />
          {/* // v2-transaction-pages-end */}
          <Route
            exact
            path={url + "forget-password"}
            component={(props) =>
              !getUserStorage() ? (
                <ForgetPasswordWithEmail {...props} />
              ) : (
                <Redirect to={url} />
              )
            }
          />
          <Route
            exact
            path={url + "profile"}
            component={(props) =>
              getUserStorage() ? (
                <Profile {...props} />
              ) : (
                <Redirect to={url + "login"} />
              )
            }
          />
          <Route
            exact
            path={url + "reset-password/:Token"}
            component={(props) =>
              !getUserStorage() ? (
                <ResetPassword {...props} />
              ) : (
                <Redirect to={url} />
              )
            }
          />
          <Route
            exact
            path={url + "acount-verification/:Token"}
            component={AccountVerification}
          />
          <Route
            exact
            path={url + "register-successful"}
            component={(props) =>
              !getUserStorage() ? (
                <RegisterSuccessful {...props} />
              ) : (
                <Redirect to={url} />
              )
            }
          />
          <Route
            exact
            path={url + "reset-successful"}
            component={(props) =>
              !getUserStorage() ? (
                <ResetSuccessful {...props} />
              ) : (
                <Redirect to={url} />
              )
            }
          />
          <Route exact path={url + "not-found"} component={NotFoundPage} />
          <Route
            exact
            path={url + "terms-and-conditions"}
            component={TermsAndConditions}
          />
          <Route exact path={url + "privacyPolicy"} component={PrivacyPolicy} />
          <Route
            exact
            path={url + "account-deletion"}
            component={AccountDeletion}
          />
          <Route exact path={url + "error-occurred"} component={ErrorPage} />
          <Redirect to={url + "not-found"} />
        </Switch>
      </Suspense>
    </>
  );
}
