import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    id:"",
    firstName:"",
    lastName:"",
    email:"",
    phone:"",
    country:""
}

export const userDataSlice = createSlice({
    name:"userData",
    initialState,
    reducers:{
        getUserData:(state,action)=>{
            state.id = action.payload._id;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.phone = action.payload.phone;
            state.country = action.payload.country;
        }
    }
})

export const { getUserData } = userDataSlice.actions

export default userDataSlice.reducer
