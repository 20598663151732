import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    senderName: "",
    senderEmail: "",
    senderCountry: "",
    senderPhone: "",
    senderCurrency: "",
    senderCashInMethod: "",
    senderCollectionPoint: "",
    receiverName: "",
    receiverEmail: "",
    receiverCountry: "",
    receiverPhone: "",
    receiverCurrency: "",
    receiverCashOutMethod: "",
    receiverCollectionPoint: "",
    receiverDeliveryAddress: "",
    senderAmount: "",
    receiverAmount: "",
    serviceFee:"",

}


export const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        senderOrderData: (state, action) => {
            state.senderName = action.payload.senderName;
            state.senderEmail = action.payload.senderEmail;
            state.senderCountry = action.payload.senderCountry;
            state.senderPhone = action.payload.senderPhone;
            state.senderCurrency = action.payload.senderCurrency;
            state.senderCashInMethod = action.payload.senderCashInMethod;
            state.senderCollectionPoint = action.payload.senderCollectionPoint;

        },
        receiverOrderData: (state, action) => {
            state.receiverName = action.payload.receiverName;
            state.receiverEmail = action.payload.receiverEmail;
            state.receiverCountry = action.payload.receiverCountry;
            state.receiverPhone = action.payload.receiverPhone;
            state.receiverCurrency = action.payload.receiverCurrency;
            state.receiverCashOutMethod = action.payload.receiverCashoutMethodName;
            state.receiverCollectionPoint = action.payload.receiverCollectionPoints;
            state.receiverDeliveryAddress = action.payload.receiverDeliveryAddress;
        },
        paymentInformation: (state, action) => {
            state.senderAmount = action.payload.senderAmount;
            state.receiverAmount = action.payload.receiverAmount;
            state.serviceFee = action.payload.serviceFee;
        }
    }
});

export const { senderOrderData, receiverOrderData, paymentInformation } = orderSlice.actions

export default orderSlice.reducer