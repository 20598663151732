import React from "react";
//Modal
import Modal from "react-bootstrap/Modal";
// React-Router-Dom
import { useHistory } from "react-router-dom";
// icon
import * as BiIcons from "react-icons/bi";
// Image
import LogoImg from "../../../assets/svgs/afrivalley-home-logo.svg";

function TokenExpireModel(props) {
  const { onHide } = props;
  const history = useHistory();

  const goToLogin = () => {
    onHide();
    localStorage.clear();
    history.push("/login");
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={false}
      >
        <div className="p-5 token-expire-model-wrapper">
          <div className="text-center">
            <img src={LogoImg} alt="" />
            <div>
              <i style={{ fontSize: "60px" }}>
                <BiIcons.BiInfoCircle />
              </i>
            </div>
          </div>
          <h1 className="text-center heading mt-4 weight-600">
            Your Login Session is Expired!
          </h1>
          <p className="text-center mt-4 sub-heading">Please login again.</p>
          <div className="d-flex justify-content-center mt-5">
            <button type="button" className="login-btn" onClick={goToLogin}>
              Login
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default TokenExpireModel;
