
export const getUserStorage = () => {
    try {
        return JSON.parse(localStorage.getItem("STORAGE"));
    }
    catch {
        return null
    }
}

export const getSenderStorage = () => {
    try {
        return JSON.parse(localStorage.getItem("SENDER-INFO"))
    } catch {
        return null
    }
}
export const getReceivertorage = () => {
    try {
        return JSON.parse(localStorage.getItem("RECEIVER-INFO"))
    } catch {
        return null
    }
}
export const getPaymentStorage = () => {
    try {
        return JSON.parse(localStorage.getItem("PAYMENT-INFO"))
    } catch {
        return null
    }
}
export const getOrderDetailStorage = () => {
    try {
        return JSON.parse(localStorage.getItem("ORDER-HISTORY-DETAIL"))
    } catch {
        return null
    }
}

