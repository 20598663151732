import React, { useEffect, useState } from "react";
import "../node_modules/bootstrap-4-react/dist/bootstrap-4-react";
import Routes from "./routes/routes";
// 3rd-party
import Aos from "aos";
import "aos/dist/aos.css";
// Model
import TokenExpireModel from "./components/Model/TokenExpire";
// services
import { verifyUserByJwt } from "./services/authService";
// slice
import { loginSignal } from "./slice/login/loginSlice";
//redux
import { useSelector, useDispatch } from "react-redux";

function App() {
  const [tokenModel, setTokenModel] = useState(false);
  const { signal } = useSelector((state) => state.loginStatus);
  console.log("signal", signal);
  const dispatch = useDispatch();
  window.onunload = () => {
    // Clear the local storage
    window.localStorage.removeItem("SENDER-INFO");
    window.localStorage.removeItem("RECEIVER-INFO");
    window.localStorage.removeItem("PAYMENT-INFO");
    window.localStorage.removeItem("ORDER-HISTORY-DETAIL");
  };
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 2500,
      easing: "ease",
      delay: 100,
      once: "true",
    });
  }, [Aos]);

  //fetching data through token start
  const fetchAndVerify = async (token) => {
    const params = {
      token: token,
    };
    const response = await verifyUserByJwt(params).then((data) => {
      return data;
    });
    console.log("Response from token verify Api: ", response);
    if (response.status === "200") {
    } else {
      setTokenModel(true);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("TOKEN");

    if (signal) {
      dispatch(loginSignal(false));
      if (token) {
        console.log("Token available: ", token);
        fetchAndVerify(token);
      }
    }
  }, [signal]);
  //fetching admin data through token end

  return (
    <>
      <TokenExpireModel show={tokenModel} onHide={() => setTokenModel(false)} />
      <Routes />
    </>
  );
}

export default App;
