import config from "../config/config.json";

// endPoint
const endPoint = config.apiUrl + "/user/login";
const registerEndPoint = config.apiUrl + "/user/register";
// Login-API
export const postLogin = async (values) => {
  try {
    const response = await fetch(endPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });
    const actualData = await response.json();
    return await actualData;
  } catch (err) {}
};
// Registration-API
export const postRegistration = async (data) => {
  try {
    const response = await fetch(registerEndPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const actualData = await response.json();
    return await actualData;
  } catch (err) {}
};

// user-order-status-update-start
export const updateOrderStatus = async (data, id) => {
  const endPointOrder = `${config.apiUrl}/order/${id}`;
  try {
    const res = await fetch(endPointOrder, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const actualData = await res.json();
    return await actualData;
  } catch (err) {
    console.log("ERROR On Update Order Status API:", err);
  }
};
// user-order-status-update-end
// Verify-user-by-JWT-start
export const verifyUserByJwt = async (data) => {
  const endPoint = `${config.apiUrl}/auth/jwtToken`;
  try {
    const response = await fetch(endPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const actualData = await response.json();
    return await actualData;
  } catch (err) {
    console.log("ERROR On Verify User By JWT API:", err);
  }
};
// Verify-user-by-JWT-end

// Generate-Token-start
export const generateJwtToken = async (data) => {
  const endPoint = `${config.apiUrl}/auth/generate/token`;
  try {
    const response = await fetch(endPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const actualData = await response.json();
    return await actualData;
  } catch (err) {
    console.log("ERROR On Create JWT token API:", err);
  }
};
// Generate-Token-end
