import { render } from "react-dom";
import App from "./App";
import React from "react";
import { BrowserRouter } from "react-router-dom";
// Redux-store
import { store } from "./app/store";
// Redux-provider
import { Provider } from "react-redux";

// Fix Dependency Issue and deploy on AWS 
render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
