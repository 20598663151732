import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const beneficiarySlice = createSlice({
  name: "beneficiary",
  initialState,
  reducers: {
    beneficiaryAddStatus: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { beneficiaryAddStatus } = beneficiarySlice.actions;

export default beneficiarySlice.reducer;
