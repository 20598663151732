import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../slice/login/loginSlice";
import userDataReducer from "../slice/login/userDataSlice";
import orderReducer from "../slice/order/orderSlice";
import addBeneficiaryReducer from "../slice/beneficiary/addBeneficiary";

export const store = configureStore({
  reducer: {
    loginStatus: loginReducer,
    loginUserData: userDataReducer,
    orderData: orderReducer,
    addBeneficiaryStatus: addBeneficiaryReducer,
  },
});
